import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo({urlVideo}) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative content_video flex justify-center md:py-2 py-10">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>
                <div className="flex md:flex-row flex-col md:w-4/5 mx-auto absolute bottom-[30px] md:bottom-[100px] lg:bottom-[90px] text-white">
                    <div className="w-full md:w-[70%] md:text-start text-center p-4">
                        <h1 className="text-[30px] md:text-[3em] lg:text-[4em]">{rpdata?.dbSlogan?.[0].slogan}</h1>
                        <p className=" px-5 md:pr-[20%]">{rpdata?.dbValues?.[0].description}</p>
                        <ButtonContent btnStyle="three" />
                    </div>

                    <div className="w-full md:w-[30%] flex justify-center">
                        <img 
                            src="https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/year%2F20%20YEARS.png?alt=media&token=038c4a0b-1e79-460e-9520-93b79c16666b"
                            alt="years experience"
                            className="w-[40%] md:w-full"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HeroVideo;